<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-900">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? `${bgbrand800} text-white` : `${textbrand100} hover:${bgbrand600}`, 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6" :class="`${textbrand300}`" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-gray-900 md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component -->
        <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div class="mt-5 flex-1 flex flex-col">
            <nav class="flex-1 px-2 space-y-1">
              <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? `${bgbrand800} text-white` : `${textbrand100} hover:${bgbrand600}`, 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6" :class="`${textbrand300}`" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset md:hidden" :class="`focus:${ringbrand500}`" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">

          <div class="ml-4 flex items-center md:ml-6">
             <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
          </div>
        </div>
      </div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
           
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

            <!-- Start Dashboard Content -->

              <form @click.prevent>
                <div class="grid grid-cols-3 md:grid-cols-5 gap-1">
                  <input type="text" v-model="vehicleSelector" disabled class="hidden">
                  <button @click="handleSelection(item.name)" v-for="item in tintServices" :key="item.id" class="px-2 py-1 rounded shadow hover:text-white hover:font-semibold" :class="`${bgbrand300} hover:${bgbrand600} `">{{item.name}}</button>
                </div>
                 
              </form>
             

            <div class="py-10">
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class=" relative">
                                <div class="space-y-5 mb-5">
                                <h3 class="text-2xl leading-6 font-medium" :class="`${textbrand500}`">
                                    Services Configuration
                                </h3>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                    Configure your service prices and service durations
                                </p>
                                
                          
                        </div>
                    </div>
                </div>

<!-- =======
Selected Vehicle
======= -->


                <div class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

                            <div v-for="(item) in tintServices" :key="item.id" >
                                <div v-if="item.name === vehicleSelector" class=" border-t border-gray-300 shadow p-5">
                                  <p class="pb-5 text-2xl uppercase font-bold" :class="`${textbrand600}`">{{item.name}}</p>
                                    <div class="flex space-x-2">

<!-- Edit mode regular -->

                                      <div>
                                        <div v-if="editHidden">
                                          <button @click="initEdit" class="bg-blue-400 px-2 rounded">
                                            Edit Mode
                                          </button>
                                        </div>
                                        <div v-if="!editHidden">
                                          <button @click="exitEdit" class="shadow-lg border rounded px-2 ml-2 bg-blue-400 text-gray-50">Exit Edit Mode</button>
                                        </div>
                                      </div>

<!-- Edit mode sale -->

                                      <div>
                                        <div v-if="saleEditHidden">
                                          <button @click="initSaleEdit" class="bg-green-400 px-2 rounded">
                                            Edit Sale Price
                                          </button>
                                        </div>
                                        <div v-if="!saleEditHidden">
                                          <button @click="exitSaleEdit" class="shadow-lg border rounded px-2 bg-green-400 text-gray-50">Exit Sale Edit Mode</button>
                                        </div>
                                      </div>
                                    </div>  
                                    <div class="mt-5 border-t border-gray-200">
                                        <dl class="divide-y divide-gray-200">
                                          <div class="py-5 flex">
                                              <h3 class=" text-lg leading-6 font-medium text-gray-900">
                                              Service Prices & Duration
                                              </h3>
                                              
                                          </div>

<!-- Service Enable/Disable -->

                                          <div class="py-2">
                                            <div  v-if="!item.hidden">
                                              <button @click="handleHidden(item.id)" class="font-bold border border-gray-600 text-gray-600 py-1 px-2 rounded shadow hover:bg-gray-800 hover:text-white">Disable Service</button>
                                            </div>
                                            <div v-if="item.hidden">
                                              <button @click="handleHidden(item.id)"  class="font-bold py-1 px-2 rounded" :class="`${bgbrand700} ${textbrand50}`">Enable Service</button>
                                            </div>
                                          </div>    

<!-- Single Window  -->

                                          <div v-if="!item.hidden">
                                            <div v-if="item.name === 'Single Window'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                              <dt class="font-medium text-lg" :class="item.singleSale ? 'text-gray-300' : 'text-gray-500'">
                                              Single Window Install Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editSinglePrice" class="text-lg font-semibold" :class="item.singleSale ? 'text-gray-300' : 'text-gray-900'">${{ item.singlePrice }}</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveSinglePrice(item.id)"  v-if="editSinglePrice">
                                                    <div class="flex justify-between">
                                                      <input v-model="singlePriceChange" :placeholder="`$${item.singlePrice}`"  class=" flex-grow" type="number">
                                                      <button v-if="editSinglePrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editSinglePrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateSinglePrice" v-if="!editSinglePrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                          </div>

<!-- Sale Price Single -->

                                          <div v-if="item.name === 'Single Window'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2 rounded " :class="{'border bg-green-100': item.singleSale, 'bg-gray-400': item.singleSale == false}">
                                              <dt class="font-medium text-gray-500 text-lg" :class="{'text-gray-800': item.singleSale}">
                                              Sale Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <p v-if="!editSaleSinglePrice" class="text-lg font-semibold" :class="!item.singleSale ? 'text-gray-300' : 'text-gray-900'">${{ item.saleSinglePrice }}</p>
                                                  <div v-else>
                                                    <div class="flex-grow flex">
                                                      <form class="flex-grow" @submit.prevent="handleSaveSaleSinglePrice(item.id)"  v-if="editSaleSinglePrice">
                                                        <div class="flex justify-between">
                                                          <input v-model="saleSinglePriceChange" :placeholder="`$${item.saleSinglePrice}`"  class=" flex-grow" type="number">
                                                          <button v-if="editSaleSinglePrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                        </div>
                                                      </form>
                                                      <div>
                                                        <button v-if="editSaleSinglePrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="flex">
                                                    <span class="ml-4 flex-shrink-0">
                                                    </span>
                                                    <div v-if="item.singleSale">
                                                      <button @click="singleSale(item.id)" v-if="!editSaleSinglePrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">On Sale</button>
                                                    </div>
                                                    <div v-if="!item.singleSale">
                                                      <button @click="singleSale(item.id)" v-if="!editSaleSinglePrice" class="shadow-lg border rounded px-2 ml-2 bg-gray-700 text-gray-50">Sale</button>
                                                    </div>
                                                    <div v-if="!saleEditHidden">
                                                      <button @click="handleUpdateSaleSinglePrice" v-if="!editSaleSinglePrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit Sale</button>
                                                    </div>
                                                  </div>
                                                  
                                              </dd>
                                          </div>

<!-- Single Window Dur                                             -->
  
                                          <div v-if="item.name === 'Single Window'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                              <dt class="font-medium text-gray-500 text-lg">
                                              Single Window Install Duration
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editSingleMinDur" class="text-lg font-semibold">{{ item.singleMinDur }} hour(s) minimum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveSingleMinDur(item.id)"  v-if="editSingleMinDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="singleMinDurChange" :placeholder="`${item.singleMinDur} hour(s) minimum`"  class=" flex-grow" type="number">
                                                      <button v-if="editSingleMinDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editSingleMinDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateSingleMinDur" v-if="!editSingleMinDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                              <dt class="font-medium text-gray-500 text-lg">
                                              
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editSingleMaxDur" class="text-lg font-semibold">{{ item.singleMaxDur }} hour(s) maximum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveSingleMaxDur(item.id)"  v-if="editSingleMaxDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="singleMaxDurChange" :placeholder="`${item.singleMaxDur} hour(s) maximum`"  class=" flex-grow" type="number">
                                                      <button v-if="editSingleMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editSingleMaxDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateSingleMaxDur" v-if="!editSingleMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                            </div>
                                          </div>
                                        

<!-- Rear Windshield -->


                                          <div v-if="!item.hidden">
                                            <div v-if="item.name === 'Rear Windshield'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                              <dt class="font-medium text-lg" :class="item.rearShieldSale ? 'text-gray-300' : 'text-gray-500'">
                                              Rear Windshield Install Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editRearShieldPrice" class="text-lg font-semibold" :class="item.rearShieldSale ? 'text-gray-300' : 'text-gray-900'">${{ item.rearShieldPrice }}</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveRearShieldPrice(item.id)"  v-if="editRearShieldPrice">
                                                    <div class="flex justify-between">
                                                      <input v-model="rearShieldPriceChange" :placeholder="`$${item.rearShieldPrice}`"  class=" flex-grow" type="number">
                                                      <button v-if="editRearShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editRearShieldPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateRearShieldPrice" v-if="!editRearShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                          </div>

<!-- Sale Price Rear Shield -->

                                          <div v-if="item.name === 'Rear Windshield'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2 rounded " :class="{'border bg-green-100': item.rearShieldSale, 'bg-gray-400': item.rearShieldSale == false}">
                                              <dt class="font-medium text-gray-500 text-lg" :class="{'text-gray-800': item.rearShieldSale}">
                                              Sale Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <p v-if="!editSaleRearShieldPrice" class="text-lg font-semibold" :class="!item.rearShieldSale ? 'text-gray-300' : 'text-gray-900'">${{ item.saleRearShieldPrice }}</p>
                                                  <div v-else>
                                                    <div class="flex-grow flex">
                                                      <form class="flex-grow" @submit.prevent="handleSaveSaleRearShieldPrice(item.id)"  v-if="editSaleRearShieldPrice">
                                                        <div class="flex justify-between">
                                                          <input v-model="saleRearShieldPriceChange" :placeholder="`$${item.saleRearShieldPrice}`"  class=" flex-grow" type="number">
                                                          <button v-if="editSaleRearShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                        </div>
                                                      </form>
                                                      <div>
                                                        <button v-if="editSaleRearShieldPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="flex">
                                                    <span class="ml-4 flex-shrink-0">
                                                    </span>
                                                    <div v-if="item.rearShieldSale">
                                                      <button @click="rearShieldSale(item.id)" v-if="!editSaleRearShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">On Sale</button>
                                                    </div>
                                                    <div v-if="!item.rearShieldSale">
                                                      <button @click="rearShieldSale(item.id)" v-if="!editSaleRearShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-gray-700 text-gray-50">Sale</button>
                                                    </div>
                                                    <div v-if="!saleEditHidden">
                                                      <button @click="handleUpdateSaleRearShieldPrice" v-if="!editSaleRearShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit Sale</button>
                                                    </div>
                                                  </div>
                                                  
                                              </dd>
                                          </div>
                                            
<!-- Rear Windshield Dur                                             -->
  
                                          <div v-if="item.name === 'Rear Windshield'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                              <dt class="font-medium text-gray-500 text-lg">
                                              Rear Windshield Install Duration
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editRearShieldMinDur" class="text-lg font-semibold">{{ item.rearShieldMinDur }} hour(s) minimum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveRearShieldMinDur(item.id)"  v-if="editRearShieldMinDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="rearShieldMinDurChange" :placeholder="`${item.rearShieldMinDur} hour(s) minimum`"  class=" flex-grow" type="number">
                                                      <button v-if="editRearShieldMinDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editRearShieldMinDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateRearShieldMinDur" v-if="!editRearShieldMinDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                              <dt class="font-medium text-gray-500 text-lg">
                                              
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editRearShieldMaxDur" class="text-lg font-semibold">{{ item.rearShieldMaxDur }} hour(s) maximum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveRearShieldMaxDur(item.id)"  v-if="editRearShieldMaxDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="rearShieldMaxDurChange" :placeholder="`${item.rearShieldMaxDur} hour(s) maximum`"  class=" flex-grow" type="number">
                                                      <button v-if="editRearShieldMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editRearShieldMaxDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateRearShieldMaxDur" v-if="!editRearShieldMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                            </div>
                                          </div>
                                          
<!-- ==== Front Windshield                                            -->
                                          
                                          <div v-if="!item.hidden">
                                            <div v-if="item.name === 'Front Windshield'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                              <dt class="font-medium text-lg" :class="item.frontShieldSale ? 'text-gray-300' : 'text-gray-500'">
                                              Front Windshield Install Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFrontShieldPrice" class="text-lg font-semibold" :class="item.frontShieldSale ? 'text-gray-300' : 'text-gray-900'">${{ item.frontShieldPrice }}</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFrontShieldPrice(item.id)"  v-if="editFrontShieldPrice">
                                                    <div class="flex justify-between">
                                                      <input v-model="frontShieldPriceChange" :placeholder="`$${item.frontShieldPrice}`"  class=" flex-grow" type="number">
                                                      <button v-if="editFrontShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFrontShieldPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFrontShieldPrice" v-if="!editFrontShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                            </div> 
                                            
                                          

<!-- Sale Price Front Shield -->

                                          <div v-if="item.name === 'Front Windshield'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2 rounded " :class="{'border bg-green-100': item.frontShieldSale, 'bg-gray-400': item.frontShieldSale == false}">
                                              <dt class="font-medium text-gray-500 text-lg" :class="{'text-gray-800': item.frontShieldSale}">
                                              Sale Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <p v-if="!editSaleFrontShieldPrice" class="text-lg font-semibold" :class="!item.frontShieldSale ? 'text-gray-300' : 'text-gray-900'">${{ item.saleFrontShieldPrice }}</p>
                                                  <div v-else>
                                                    <div class="flex-grow flex">
                                                      <form class="flex-grow" @submit.prevent="handleSaveSaleFrontShieldPrice(item.id)"  v-if="editSaleFrontShieldPrice">
                                                        <div class="flex justify-between">
                                                          <input v-model="saleFrontShieldPriceChange" :placeholder="`$${item.saleFrontShieldPrice}`"  class=" flex-grow" type="number">
                                                          <button v-if="editSaleFrontShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                        </div>
                                                      </form>
                                                      <div>
                                                        <button v-if="editSaleFrontShieldPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="flex">
                                                    <span class="ml-4 flex-shrink-0">
                                                    </span>
                                                    <div v-if="item.frontShieldSale">
                                                      <button @click="frontShieldSale(item.id)" v-if="!editSaleFrontShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">On Sale</button>
                                                    </div>
                                                    <div v-if="!item.frontShieldSale">
                                                      <button @click="frontShieldSale(item.id)" v-if="!editSaleFrontShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-gray-700 text-gray-50">Sale</button>
                                                    </div>
                                                    <div v-if="!saleEditHidden">
                                                      <button @click="handleUpdateSaleFrontShieldPrice" v-if="!editSaleFrontShieldPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit Sale</button>
                                                    </div>
                                                  </div>
                                                  
                                              </dd>
                                          </div>
                                            
<!-- Front Wind Dur                                             -->
  
                                          <div v-if="item.name === 'Front Windshield'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                              <dt class="font-medium text-gray-500 text-lg">
                                              Front Windshield Install Duration
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFrontShieldMinDur" class="text-lg font-semibold">{{ item.frontShieldMinDur }} hour(s) minimum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFrontShieldMinDur(item.id)"  v-if="editFrontShieldMinDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="frontShieldMinDurChange" :placeholder="`${item.frontShieldMinDur} hour(s) minimum`"  class=" flex-grow" type="number">
                                                      <button v-if="editFrontShieldMinDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFrontShieldMinDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFrontShieldMinDur" v-if="!editFrontShieldMinDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                              <dt class="font-medium text-gray-500 text-lg">
                                              
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFrontShieldMaxDur" class="text-lg font-semibold">{{ item.frontShieldMaxDur }} hour(s) maximum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFrontShieldMaxDur(item.id)"  v-if="editFrontShieldMaxDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="frontShieldMaxDurChange" :placeholder="`${item.frontShieldMaxDur} hour(s) maximum`"  class=" flex-grow" type="number">
                                                      <button v-if="editFrontShieldMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFrontShieldMaxDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFrontShieldMaxDur" v-if="!editFrontShieldMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                              </dd>
                                            </div>
                                          </div>
                                        

<!-- Front Windows -->

                                          <div v-if="!item.hidden">
                                            <div v-if="item.name === 'Front Windows'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2">
                                              <dt class="font-medium text-lg" :class="item.frontSale ? 'text-gray-300' : 'text-gray-500'">
                                              Front Windows Install Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFrontPrice" class="text-lg font-semibold" :class="item.frontSale ? 'text-gray-300' : 'text-gray-900'">${{ item.frontPrice }}</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFrontPrice(item.id)"  v-if="editFrontPrice">
                                                    <div class="flex justify-between">
                                                      <input v-model="frontPriceChange" :placeholder="`$${item.frontPrice}`"  class=" flex-grow" type="number">
                                                      <button v-if="editFrontPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFrontPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFrontPrice" v-if="!editFrontPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                          </div>

<!-- Sale Price FRONT -->

                                          <div v-if="item.name === 'Front Windows'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2 rounded " :class="{'border bg-green-100': item.frontSale, 'bg-gray-400': item.frontSale == false}">
                                              <dt class="font-medium text-gray-500 text-lg" :class="{'text-gray-800': item.frontSale}">
                                              Front Sale Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <p v-if="!editSaleFrontPrice" class="text-lg font-semibold" :class="!item.frontSale ? 'text-gray-300' : 'text-gray-900'">${{ item.saleFrontPrice }}</p>
                                                  <div v-else>
                                                    <div class="flex-grow flex">
                                                      <form class="flex-grow" @submit.prevent="handleSaveSaleFrontPrice(item.id)"  v-if="editSaleFrontPrice">
                                                        <div class="flex justify-between">
                                                          <input v-model="saleFrontPriceChange" :placeholder="`$${item.saleFrontPrice}`"  class=" flex-grow" type="number">
                                                          <button v-if="editSaleFrontPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                        </div>
                                                      </form>
                                                      <div>
                                                        <button v-if="editSaleFrontPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="flex">
                                                    <span class="ml-4 flex-shrink-0">
                                                    </span>
                                                    <div v-if="item.frontSale">
                                                      <button @click="frontSale(item.id)" v-if="!editSaleFrontPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">On Sale</button>
                                                    </div>
                                                    <div v-if="!item.frontSale">
                                                      <button @click="frontSale(item.id)" v-if="!editSaleFrontPrice" class="shadow-lg border rounded px-2 ml-2 bg-gray-700 text-gray-50">Sale</button>
                                                    </div>
                                                    <div v-if="!saleEditHidden">
                                                      <button @click="handleUpdateSaleFrontPrice" v-if="!editSaleFrontPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit Sale</button>
                                                    </div>
                                                  </div>
                                                  
                                              </dd>
                                          </div>
                                            
<!-- Front Install Dur  -->

                                          <div v-if="item.name === 'Front Windows'" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2">
                                              <dt class="font-medium text-gray-500 text-lg">
                                              Front Windows Install Duration
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFrontMinDur" class="text-lg font-semibold">{{ item.frontMinDur }} hour(s) minimum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFrontMinDur(item.id)"  v-if="editFrontMinDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="frontMinDurChange" :placeholder="`${item.frontMinDur} hour(s) minimum`"  class=" flex-grow" type="number">
                                                      <button v-if="editFrontMinDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFrontMinDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFrontMinDur" v-if="!editFrontMinDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                              <dt class="font-medium text-gray-500 text-lg">
                                              
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFrontMaxDur" class="text-lg font-semibold">{{ item.frontMaxDur }} hour(s) maximum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFrontMaxDur(item.id)"  v-if="editFrontMaxDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="frontMaxDurChange" :placeholder="`${item.frontMaxDur} hour(s) maximum`"  class=" flex-grow" type="number">
                                                      <button v-if="editFrontMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFrontMaxDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFrontMaxDur" v-if="!editFrontMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                              </dd>
                                            </div>
                                          </div>
                                          
<!-- Rear Service  -->                                          
                                        <div v-if="!item.hidden">
                                          <div v-if="item.rearPrice" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2">
                                              <dt class="font-medium text-gray-500 text-lg" :class="item.rearSale ? 'text-gray-300' : 'text-gray-500'">
                                              Rear Install Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editRearPrice" class="text-lg font-semibold" :class="item.rearSale ? 'text-gray-300' : 'text-gray-900'">${{ item.rearPrice }}</p>
                                                  <div class="flex-grow flex justify-between">
                                                    <form class="flex-grow" @submit.prevent="handleSaveRearPrice(item.id)"  v-if="editRearPrice">
                                                      <div class="flex justify-between">
                                                        <input v-model="rearPriceChange" :placeholder="`$${item.rearPrice}`"  class=" flex-grow" type="number">
                                                        <button v-if="editRearPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                      </div>
                                                    </form>
                                                    <div>
                                                      <button v-if="editRearPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                    </div>
                                                
                                                    <span class="ml-4 flex-shrink-0">
                                                    </span>
                                                    <div v-if="!editHidden">
                                                      <button @click="handleUpdateRearPrice" v-if="!editRearPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                    </div>
                                                  </div>
                                              </dd>
                                          </div>



<!-- Sale Price Rear -->

                                          <div v-if="item.saleRearPrice" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2 rounded " :class="{'border bg-green-100': item.rearSale, 'bg-gray-400': item.rearSale == false}">
                                              <dt class="font-medium text-gray-500 text-lg" :class="{'text-gray-800': item.rearSale}">
                                              Rear Sale Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <p v-if="!editSaleRearPrice" class="text-lg font-semibold">${{ item.saleRearPrice }}</p>
                                                  <div v-else>
                                                    <div class="flex-grow flex">
                                                      <form class="flex-grow" @submit.prevent="handleSaveSaleRearPrice(item.id)"  v-if="editSaleRearPrice">
                                                        <div class="flex justify-between">
                                                          <input v-model="saleRearPriceChange" :placeholder="`$${item.saleRearPrice}`"  class=" flex-grow" type="number">
                                                          <button v-if="editSaleRearPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                        </div>
                                                      </form>
                                                      <div>
                                                        <button v-if="editSaleRearPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="flex">
                                                    <span class="ml-4 flex-shrink-0">
                                                    </span>
                                                    <div v-if="item.rearSale">
                                                      <button @click="rearSale(item.id)" v-if="!editSaleRearPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">On Sale</button>
                                                    </div>
                                                    <div v-if="!item.rearSale">
                                                      <button @click="rearSale(item.id)" v-if="!editSaleRearPrice" class="shadow-lg border rounded px-2 ml-2 bg-gray-700 text-gray-50">Sale</button>
                                                    </div>
                                                    <div v-if="!saleEditHidden">
                                                      <button @click="handleUpdateSaleRearPrice" v-if="!editSaleRearPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit Sale</button>
                                                    </div>
                                                  </div>
                                                  
                                              </dd>
                                          </div>                                          
                                            
<!-- Rear Install Dur -->
                                          <div v-if="item.rearMinDur" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2">
                                              <dt class="font-medium text-gray-500 text-lg">
                                              Rear Install Duration
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editRearMinDur" class="text-lg font-semibold">{{ item.rearMinDur }} hour(s) minimum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveRearMinDur(item.id)"  v-if="editRearMinDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="rearMinDurChange" :placeholder="`${item.rearMinDur} hour(s) minimum`"  class=" flex-grow" type="number">
                                                      <button v-if="editRearMinDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editRearMinDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateRearMinDur" v-if="!editRearMinDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                              <dt class="font-medium text-gray-500 text-lg">
                                              
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editRearMaxDur" class="text-lg font-semibold">{{ item.rearMaxDur }} hours maximum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveRearMaxDur(item.id)"  v-if="editRearMaxDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="rearMaxDurChange" :placeholder="`${item.rearMaxDur} hours maximum`"  class=" flex-grow" type="number">
                                                      <button v-if="editRearMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editRearMaxDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateRearMaxDur" v-if="!editRearMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                          </div>
                                          
<!-- Full Service  -->

                                          <div v-if="item.fullPrice" class="p-2 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                              <dt class="font-medium text-lg" :class="item.fullSale ? 'text-gray-300' : 'text-gray-500'">
                                              Full Install Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <p :class="item.fullSale ? 'text-gray-300' : 'text-gray-900'" v-if="!editFullPrice" class="text-lg font-semibold">${{ item.fullPrice }}</p>
                                                  <div class="flex-grow flex">
                                                    <form class="flex-grow" @submit.prevent="handleSaveFullPrice(item.id)"  v-if="editFullPrice">
                                                      <div class="flex justify-between">
                                                        <input v-model="fullPriceChange" :placeholder="`$${item.fullPrice}`"  class=" flex-grow" type="number">
                                                        <button v-if="editFullPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                      </div>
                                                    </form>
                                                    <div>
                                                      <button v-if="editFullPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                    </div>
                                                  </div>
                                                
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFullPrice" v-if="!editFullPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                          </div>

<!-- Sale Price Full -->

                                          <div v-if="item.saleFullPrice" class=" py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2 rounded " :class="{'border bg-green-100': item.fullSale, 'bg-gray-400': item.fullSale == false}">
                                              <dt class="font-medium text-gray-500 text-lg" :class="{'text-gray-800': item.fullSale}">
                                              Full Sale Price
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <p v-if="!editSaleFullPrice" class="text-lg font-semibold">${{ item.saleFullPrice }}</p>
                                                  <div v-else>
                                                    <div class="flex-grow flex">
                                                      <form class="flex-grow" @submit.prevent="handleSaveSaleFullPrice(item.id)"  v-if="editSaleFullPrice">
                                                        <div class="flex justify-between">
                                                          <input v-model="saleFullPriceChange" :placeholder="`$${item.saleFullPrice}`"  class=" flex-grow" type="number">
                                                          <button v-if="editSaleFullPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                        </div>
                                                      </form>
                                                      <div>
                                                        <button v-if="editSaleFullPrice" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="flex">
                                                    <span class="ml-4 flex-shrink-0">
                                                    </span>
                                                    <div v-if="item.fullSale">
                                                      <button @click="fullSale(item.id)" v-if="!editSaleFullPrice" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">On Sale</button>
                                                    </div>
                                                    <div v-if="!item.fullSale">
                                                      <button @click="fullSale(item.id)" v-if="!editSaleFullPrice" class="shadow-lg border rounded px-2 ml-2 bg-gray-700 text-gray-50">Sale</button>
                                                    </div>
                                                    <div v-if="!saleEditHidden">
                                                      <button @click="handleUpdateSaleFullPrice" v-if="!editSaleFullPrice" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit Sale</button>
                                                    </div>
                                                  </div>
                                                  
                                              </dd>
                                          </div>
                                          
<!-- Full Install Dur  -->
                                          <div v-if="item.fullMinDur" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 p-2">
                                              <dt class="font-medium text-gray-500 text-lg">
                                              Full Install Duration
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFullMinDur" class="text-lg font-semibold">{{ item.fullMinDur }} hour(s) minimum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFullMinDur(item.id)"  v-if="editFullMinDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="fullMinDurChange" :placeholder="`${item.fullMinDur} hour(s) minimum`"  class=" flex-grow" type="number">
                                                      <button v-if="editFullMinDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFullMinDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFullMinDur" v-if="!editFullMinDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                              <dt class="font-medium text-gray-500 text-lg">
                                              
                                              </dt>
                                              
                                              <dd class="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                  <p v-if="!editFullMaxDur" class="text-lg font-semibold">{{ item.fullMaxDur }} hours maximum</p>
                                                  <div class="flex-grow flex">
                                                  <form class="flex-grow" @submit.prevent="handleSaveFullMaxDur(item.id)"  v-if="editFullMaxDur">
                                                    <div class="flex justify-between">
                                                      <input v-model="fullMaxDurChange" :placeholder="`${item.fullMaxDur} hours maximum`"  class=" flex-grow" type="number">
                                                      <button v-if="editFullMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-green-500 text-gray-50">Save & Refresh</button>
                                                    </div>
                                                  </form>
                                                  <div>
                                                    <button v-if="editFullMaxDur" @click="cancelEdit" class="shadow-lg border rounded p-2 ml-2 bg-red-500 text-gray-50">Cancel</button>
                                                  </div>
                                                  </div>
                                                  
                                                  
                                                  <span class="ml-4 flex-shrink-0">
                                                  </span>
                                                  <div v-if="!editHidden">
                                                    <button @click="handleUpdateFullMaxDur" v-if="!editFullMaxDur" class="shadow-lg border rounded px-2 ml-2 bg-blue-500 text-gray-50">Edit</button>
                                                  </div>
                                                  
                                              </dd>
                                          </div>
                                        </div>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
            <!-- /End Dashboard -->
          </div>
        </div>
      </main>
    </div>
  </div>
  
</template>

<script>
import { ref } from 'vue'
import getTintService from '@/composables/getTintService'
import useDocument from '@/composables/useDocument'
import configSetup from '@/setup.js'

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'

const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

const galleryIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const warrantyIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
</svg>

const galleryListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
</svg>

const warrantyListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
</svg>

const pricesIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
</svg>

const calendarIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const navigation = [
  // { name: 'Settings', href: 'Settings', icon: settingsIcon, current: false },
  { name: 'Booking Request', href: 'BookingRequests', icon: calendarIcon, current: false },
  { name: 'Gallery Upload', href: 'ImageLoader', icon: galleryIcon, current: false },
  { name: 'Gallery Edit', href: 'GalleryEdit', icon: galleryListIcon, current: false },
  { name: 'Warranty Creator', href: 'WarrantyCreator', icon: warrantyIcon, current: false },
  { name: 'Warranty Search', href: 'WarrantyList', icon: warrantyListIcon, current: false },
  { name: 'Service Prices', href: 'ServicePrices', icon: pricesIcon, current: true },

]

export default {
    created() {
        const {company} = configSetup()
        document.title = `${company} | Service Prices`
    },

    components: {
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        BellIcon,
        MenuAlt2Icon,
        SearchIcon,
        XIcon,
    },

    setup(){
        const sidebarOpen = ref(false)
        const {bgbrand300,
        bgbrand600,
        bgbrand700,
        bgbrand800,
        textbrand50,
        textbrand100,
        textbrand300,
        textbrand500,
        textbrand600,
        ringbrand500,
        dbTintingServices} = configSetup()
        const {tintServices} = getTintService()



// Vehicle Selector

    const vehicleSelector = ref('')

    const handleSelection = (selection) => {
      vehicleSelector.value = selection
      exitEdit()
    }

// Edit Mode

        let editHidden = ref(true)
        const initEdit = () => {
          editHidden.value = false
        }
        const exitEdit = () => {
          editHidden.value = true
          cancelEdit()
        }

        let saleEditHidden = ref(true)
        const initSaleEdit = () => {
          saleEditHidden.value = false
        }
        const exitSaleEdit = () => {
          saleEditHidden.value = true
          cancelEdit()
        }

// Edit Single Price


        let editSinglePrice = ref(false)
        const singlePriceChange = ref(null)
        const handleUpdateSinglePrice = () => {
          editSinglePrice.value = true
        }

        const handleSaveSinglePrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            singlePrice:singlePriceChange.value
            
          })
          editSinglePrice.value = false
          singlePriceChange.value = null
        }

// Sale Single

        let editSaleSinglePrice = ref(false)
        const saleSinglePriceChange = ref(null)
        const handleUpdateSaleSinglePrice = () => {
          editSaleSinglePrice.value = true
        }

        const handleSaveSaleSinglePrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            saleSinglePrice: saleSinglePriceChange.value
            
          })
          editSaleSinglePrice.value = false
          saleSinglePriceChange.value = null
        }    

        let onSaleSingle = ref(null)

        const singleSale = async (serviceId) => {
          onSaleSingle.value = !onSaleSingle.value
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
          singleSale: onSaleSingle.value
          })
          
        }                    

// Edit Single Duration

//Minimum

        let editSingleMinDur = ref(false)
        const singleMinDurChange = ref(null)
        const handleUpdateSingleMinDur = () => {
          editSingleMinDur.value = true
        }

        const handleSaveSingleMinDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            singleMinDur: singleMinDurChange.value
            
          })
          editSingleMinDur.value = false
          singleMinDurChange.value = null
        }             

//Maximum

        let editSingleMaxDur = ref(false)
        const singleMaxDurChange = ref(null)
        const handleUpdateSingleMaxDur = () => {
          editSingleMaxDur.value = true
        }

        const handleSaveSingleMaxDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            singleMaxDur: singleMaxDurChange.value
            
          })
          editSingleMaxDur.value = false
          singleMaxDurChange.value = null
        }                      

// Edit Rear Windshield Price

        let editRearShieldPrice = ref(false)
        const rearShieldPriceChange = ref(null)
        const handleUpdateRearShieldPrice = () => {
          editRearShieldPrice.value = true
        }

        const handleSaveRearShieldPrice = async (serviceId) => {
         const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            rearShieldPrice:rearShieldPriceChange.value
            
          })
          editRearShieldPrice.value = false
          rearShieldPriceChange.value = null
        }

// Sale Rear Shield

        let editSaleRearShieldPrice = ref(false)
        const saleRearShieldPriceChange = ref(null)
        const handleUpdateSaleRearShieldPrice = () => {
          editSaleRearShieldPrice.value = true
        }

        const handleSaveSaleRearShieldPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            saleRearShieldPrice: saleRearShieldPriceChange.value
            
          })
          editSaleRearShieldPrice.value = false
          saleRearShieldPriceChange.value = null
        }    

        let onSaleRearShield = ref(null)

        const rearShieldSale = async (serviceId) => {
          onSaleRearShield.value = !onSaleRearShield.value
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
          rearShieldSale: onSaleRearShield.value
          })
          
        }                          

// Edit Rear Windshield Duration

//Minimum

        let editRearShieldMinDur = ref(false)
        const rearShieldMinDurChange = ref(null)
        const handleUpdateRearShieldMinDur = () => {
          editRearShieldMinDur.value = true
        }

        const handleSaveRearShieldMinDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            rearShieldMinDur: rearShieldMinDurChange.value
            
          })
          editRearShieldMinDur.value = false
          rearShieldMinDurChange.value = null
        }             

//Maximum

        let editRearShieldMaxDur = ref(false)
        const rearShieldMaxDurChange = ref(null)
        const handleUpdateRearShieldMaxDur = () => {
          editRearShieldMaxDur.value = true
        }

        const handleSaveRearShieldMaxDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            rearShieldMaxDur: rearShieldMaxDurChange.value
            
          })
          editRearShieldMaxDur.value = false
          rearShieldMaxDurChange.value = null
        }                                   

// Edit Front Windshield Price

        let editFrontShieldPrice = ref(false)
        const frontShieldPriceChange = ref(null)
        const handleUpdateFrontShieldPrice = () => {
          editFrontShieldPrice.value = true
        }

        const handleSaveFrontShieldPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            frontShieldPrice:frontShieldPriceChange.value
            
          })
          editFrontShieldPrice.value = false
          frontShieldPriceChange.value = null
        }

// Sale Front Shield

        let editSaleFrontShieldPrice = ref(false)
        const saleFrontShieldPriceChange = ref(null)
        const handleUpdateSaleFrontShieldPrice = () => {
          editSaleFrontShieldPrice.value = true
        }

        const handleSaveSaleFrontShieldPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            saleFrontShieldPrice: saleFrontShieldPriceChange.value
            
          })
          editSaleFrontShieldPrice.value = false
          saleFrontShieldPriceChange.value = null
        }    

        let onSaleFrontShield = ref(null)

        const frontShieldSale = async (serviceId) => {
          onSaleFrontShield.value = !onSaleFrontShield.value
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
          frontShieldSale: onSaleFrontShield.value
          })
          
        }             

// Edit Front Windshield Duration

//Minimum

        let editFrontShieldMinDur = ref(false)
        const frontShieldMinDurChange = ref(null)
        const handleUpdateFrontShieldMinDur = () => {
          editFrontShieldMinDur.value = true
        }

        const handleSaveFrontShieldMinDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            frontShieldMinDur: frontShieldMinDurChange.value
            
          })
          editFrontShieldMinDur.value = false
          frontShieldMinDurChange.value = null
        }             

//Maximum

        let editFrontShieldMaxDur = ref(false)
        const frontShieldMaxDurChange = ref(null)
        const handleUpdateFrontShieldMaxDur = () => {
          editFrontShieldMaxDur.value = true
        }

        const handleSaveFrontShieldMaxDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            frontShieldMaxDur: frontShieldMaxDurChange.value
            
          })
          editFrontShieldMaxDur.value = false
          frontShieldMaxDurChange.value = null
        }                                

// Edit Front Windows Price

        let editFrontPrice = ref(false)
        const frontPriceChange = ref(null)
        const handleUpdateFrontPrice = () => {
          editFrontPrice.value = true
        }

        const handleSaveFrontPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            frontPrice:frontPriceChange.value
            
          })
          editFrontPrice.value = false
          frontPriceChange.value = null
        }

// Sale Front

        let editSaleFrontPrice = ref(false)
        const saleFrontPriceChange = ref(null)
        const handleUpdateSaleFrontPrice = () => {
          editSaleFrontPrice.value = true
        }

        const handleSaveSaleFrontPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            saleFrontPrice: saleFrontPriceChange.value
            
          })
          editSaleFrontPrice.value = false
          saleFrontPriceChange.value = null
        }    

        let onSaleFront = ref(null)

        const frontSale = async (serviceId) => {
          onSaleFront.value = !onSaleFront.value
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
          frontSale: onSaleFront.value
          })
          
        }

// Edit Front Windows Duration

//Minimum

        let editFrontMinDur = ref(false)
        const frontMinDurChange = ref(null)
        const handleUpdateFrontMinDur = () => {
          editFrontMinDur.value = true
        }

        const handleSaveFrontMinDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            frontMinDur: frontMinDurChange.value
            
          })
          editFrontMinDur.value = false
          frontMinDurChange.value = null
        }             

//Maximum

        let editFrontMaxDur = ref(false)
        const frontMaxDurChange = ref(null)
        const handleUpdateFrontMaxDur = () => {
          editFrontMaxDur.value = true
        }

        const handleSaveFrontMaxDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            frontMaxDur: frontMaxDurChange.value
            
          })
          editFrontMaxDur.value = false
          frontMaxDurChange.value = null
        }                 


// Edit Rear Price



        let editRearPrice = ref(false)
        const rearPriceChange = ref(null)
        const handleUpdateRearPrice = () => {
          editRearPrice.value = true
        }

        const handleSaveRearPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            rearPrice: rearPriceChange.value
            
          })
          editRearPrice.value = false
          rearPriceChange.value = null
        }             

// Edit Rear Duration

//Minimum

        let editRearMinDur = ref(false)
        const rearMinDurChange = ref(null)
        const handleUpdateRearMinDur = () => {
          editRearMinDur.value = true
        }

        const handleSaveRearMinDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            rearMinDur: rearMinDurChange.value
            
          })
          editRearMinDur.value = false
          rearMinDurChange.value = null
        }             

//Maximum

        let editRearMaxDur = ref(false)
        const rearMaxDurChange = ref(null)
        const handleUpdateRearMaxDur = () => {
          editRearMaxDur.value = true
        }

        const handleSaveRearMaxDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            rearMaxDur: rearMaxDurChange.value
            
          })
          editRearMaxDur.value = false
          rearMaxDurChange.value = null
        }             

// Edit Full Price

        let editFullPrice = ref(false)
        const fullPriceChange = ref(null)
        const handleUpdateFullPrice = () => {
          editFullPrice.value = true
        }

        const handleSaveFullPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            fullPrice: fullPriceChange.value
            
          })
          editFullPrice.value = false
          fullPriceChange.value = null
        }             

// Edit Full Duration

//Minimum

        let editFullMinDur = ref(false)
        const fullMinDurChange = ref(null)
        const handleUpdateFullMinDur = () => {
          editFullMinDur.value = true
        }

        const handleSaveFullMinDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            fullMinDur: fullMinDurChange.value
            
          })
          editFullMinDur.value = false
          fullMinDurChange.value = null
        }             

//Maximum

        let editFullMaxDur = ref(false)
        const fullMaxDurChange = ref(null)
        const handleUpdateFullMaxDur = () => {
          editFullMaxDur.value = true
        }

        const handleSaveFullMaxDur = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            fullMaxDur: fullMaxDurChange.value
            
          })
          editFullMaxDur.value = false
          fullMaxDurChange.value = null
        }

// Cancel Edit

        const cancelEdit = () => {
          editSinglePrice.value = false
          editSingleMinDur.value = false
          editSingleMaxDur.value = false
          editRearShieldPrice.value = false
          editRearShieldMinDur.value = false
          editRearShieldMaxDur.value = false
          editFrontShieldPrice.value = false
          editFrontShieldMinDur.value = false
          editFrontShieldMaxDur.value = false
          editFrontPrice.value = false
          editFrontMinDur.value = false
          editFrontMaxDur.value = false
          editRearPrice.value = false
          editRearMinDur.value = false
          editRearMaxDur.value = false
          editFullPrice.value = false
          editFullMinDur.value = false
          editFullMaxDur.value = false
          editSaleFullPrice.value = false
          editSaleRearPrice.value = false
          editSaleFrontPrice.value = false
          editSaleFrontShieldPrice.value = false
          editSaleRearShieldPrice.value = false
          editSaleSinglePrice.value = false
        }             

// Hide Service

        let serviceHidden = ref(null)

        const handleHidden = async (serviceId) => {
          serviceHidden.value = !serviceHidden.value
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            hidden: serviceHidden.value
            
          })
        }


// Sale Rear

        let editSaleRearPrice = ref(false)
        const saleRearPriceChange = ref(null)
        const handleUpdateSaleRearPrice = () => {
          editSaleRearPrice.value = true
        }

        const handleSaveSaleRearPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            saleRearPrice: saleRearPriceChange.value
            
          })
          editSaleRearPrice.value = false
          saleRearPriceChange.value = null
        }    

        let onSaleRear = ref(null)

        const rearSale = async (serviceId) => {
          onSaleRear.value = !onSaleRear.value
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
          rearSale: onSaleRear.value
          })
          
        }

// Sale Full

        let editSaleFullPrice = ref(false)
        const saleFullPriceChange = ref(null)
        const handleUpdateSaleFullPrice = () => {
          editSaleFullPrice.value = true
        }

        const handleSaveSaleFullPrice = async (serviceId) => {
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
            saleFullPrice: saleFullPriceChange.value
            
          })
          editSaleFullPrice.value = false
          saleFullPriceChange.value = null
        }    

        let onSaleFull = ref(null)

        const fullSale = async (serviceId) => {
          onSaleFull.value = !onSaleFull.value
          const {updateTintPrices} = await useDocument('companyProfile', serviceId, 'tintingServices')
          await updateTintPrices({
          fullSale: onSaleFull.value
          })
          
        }
        
        
        
    return {
        onSaleRear,
        rearSale,

        editSaleRearPrice,
        saleRearPriceChange,
        handleUpdateSaleRearPrice,
        handleSaveSaleRearPrice,  

        onSaleFull,
        fullSale,

        editSaleFullPrice,
        saleFullPriceChange,
        handleUpdateSaleFullPrice,
        handleSaveSaleFullPrice,   

        onSaleFront,
        frontSale,

        editSaleFrontPrice,
        saleFrontPriceChange,
        handleUpdateSaleFrontPrice,
        handleSaveSaleFrontPrice,    

        onSaleFrontShield,
        frontShieldSale,

        editSaleFrontShieldPrice,
        saleFrontShieldPriceChange,
        handleUpdateSaleFrontShieldPrice,
        handleSaveSaleFrontShieldPrice,      

        onSaleRearShield,
        rearShieldSale,

        editSaleRearShieldPrice,
        saleRearShieldPriceChange,
        handleUpdateSaleRearShieldPrice,
        handleSaveSaleRearShieldPrice,  
      

        onSaleSingle,
        singleSale,

        editSaleSinglePrice,
        saleSinglePriceChange,
        handleUpdateSaleSinglePrice,
        handleSaveSaleSinglePrice,  

        navigation,
        sidebarOpen,
        bgbrand300,
        bgbrand600,
        bgbrand700,
        bgbrand800,
        textbrand50,
        textbrand100,
        textbrand300,
        textbrand500,
        textbrand600,
        ringbrand500,

        vehicleSelector,
        handleSelection,

        serviceHidden,
        handleHidden,

        editSinglePrice,
        singlePriceChange,
        handleUpdateSinglePrice,
        handleSaveSinglePrice,   

        editSingleMinDur,
        singleMinDurChange,
        handleUpdateSingleMinDur,
        handleSaveSingleMinDur,    
   

        editSingleMaxDur,
        singleMaxDurChange,
        handleUpdateSingleMaxDur,
        handleSaveSingleMaxDur,    

        editRearShieldPrice,
        rearShieldPriceChange,
        handleUpdateRearShieldPrice,
        handleSaveRearShieldPrice,

        editRearShieldMinDur,
        rearShieldMinDurChange,
        handleUpdateRearShieldMinDur,
        handleSaveRearShieldMinDur,    
   

        editRearShieldMaxDur,
        rearShieldMaxDurChange,
        handleUpdateRearShieldMaxDur,
        handleSaveRearShieldMaxDur,    

        editFrontShieldPrice,
        frontShieldPriceChange,
        handleUpdateFrontShieldPrice,
        handleSaveFrontShieldPrice,

        editFrontShieldMinDur,
        frontShieldMinDurChange,
        handleUpdateFrontShieldMinDur,
        handleSaveFrontShieldMinDur,    
   

        editFrontShieldMaxDur,
        frontShieldMaxDurChange,
        handleUpdateFrontShieldMaxDur,
        handleSaveFrontShieldMaxDur,      

        editFrontPrice,
        frontPriceChange,
        handleUpdateFrontPrice,
        handleSaveFrontPrice,   

        editFrontMinDur,
        frontMinDurChange,
        handleUpdateFrontMinDur,
        handleSaveFrontMinDur,    
   

        editFrontMaxDur,
        frontMaxDurChange,
        handleUpdateFrontMaxDur,
        handleSaveFrontMaxDur,    

        editRearPrice,
        rearPriceChange,
        handleUpdateRearPrice,
        handleSaveRearPrice,  

        editRearMinDur,
        rearMinDurChange,
        handleUpdateRearMinDur,
        handleSaveRearMinDur,   

        editRearMaxDur,
        rearMaxDurChange,
        handleUpdateRearMaxDur,
        handleSaveRearMaxDur,

        editFullPrice,
        fullPriceChange,
        handleUpdateFullPrice,
        handleSaveFullPrice,  

        editFullMinDur,
        fullMinDurChange,
        handleUpdateFullMinDur,
        handleSaveFullMinDur,   

        editFullMaxDur,
        fullMaxDurChange,
        handleUpdateFullMaxDur,
        handleSaveFullMaxDur,  

        cancelEdit,

        editHidden,
        initEdit,
        exitEdit,

        saleEditHidden,
        initSaleEdit,
        exitSaleEdit,
        tintServices,}
    }

}
</script>